.navScroll-enter {
  background-color: #020611;
  padding-top: 0 !important;
}

.navScroll-enter-active {
  background-color: transparent;
  padding-top: 1.5% !important;
  transition: background-color 500ms, padding-top 500ms !important;
}

.navScroll-enter-done {
  background-color: transparent;
  padding-top: 1.5% !important;
}

.navScroll-exit {
  background-color: transparent;
  padding-top: 1.5% !important;
}

.navScroll-exit-active {
  background-color: #020611;
  transition: background-color 500ms, padding-top 500ms !important;
  padding-top: 0 !important;
}

.navScroll-exit-done {
  background-color: #020611;
  padding-top: 0 !important;
}

.navbar {
  width: 100%;
  position: fixed;
  padding-top: 1.5%;
  z-index: 4;
}

.title {
  padding-top: 32vh;
  text-align: center;
  font-size: 62px;
  padding-left: 18px;
  padding-right: 18px;
}

@media (max-width: 1036px) {
.title{
  padding-top: 14vh;
  font-size: 36px;
}
}

.box {
  display: flex;
  flex-direction: row;
  margin-top: 5%;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-shadow: 0 0 10px rgba(33, 33, 33, 0.2);
  background-color: transparent;
  border: 0;
}

.link-logiciel-card {
  color: #020611;
  text-decoration: none;
}

.link-logiciel-card:hover {
  color: #3936e0;
}

.box:hover {
  box-shadow: 0 0 50px rgba(33, 33, 33, 0.2);
  color: #3936e0;
}

.background2 {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.background2::after {
  content: "";
  background-image: "Assets/SoftwareHome2.jpeg";
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.1;
}
